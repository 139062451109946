import React, { useState } from 'react'

type DomainListHeaderProps = {
  domainCount: number
  domainUserCount: number
  onAddDomainClick: () => void
}

function DomainListHeader({
  domainCount,
  domainUserCount,
  onAddDomainClick,
}: DomainListHeaderProps) {
  return (
    <div className='flex w-full items-center justify-between'>
      <div className='mx-6 flex items-center space-x-2'>
        {/* 모달에서 한번에 하는게 편할듯... */}
        {/* 추후 작업~ 필터 제거 버튼도 추가 */}
        {/* <div className='z-10 dropdown dropdown-hover'>
          <div tabIndex={0} className='p-1'>
            <i className='fa-solid fa-filter fa-xs'></i>
          </div>
          <ul
            tabIndex={0}
            className='dropdown-content menu bg-base-100 rounded-box z-[1] w-40 p-2 shadow'
          >
            <li>
              <a>해외</a>
            </li>
            <li>
              <a>국내</a>
            </li>
          </ul>
        </div> */}
        <span className='badge badge-ghost text-xs'>전체 {domainCount}</span>
        <span className='badge badge-ghost text-xs'>사용자 {domainUserCount}</span>
      </div>
      <div className='px-3 pb-1'>
        <button className='btn-ghost btn-sm btn items-center space-x-1' onClick={onAddDomainClick}>
          <i className='fa-solid fa-circle-plus text-primary'></i>
          <span className='text-sm text-primary'>도메인 추가</span>
        </button>
      </div>
    </div>
  )
}

export default DomainListHeader
