import React, { useCallback, useEffect, useRef, useState } from 'react'
import { initialDomain } from '@/module/home/context'
import { DomainInfo, SchoolInfoDto, TSchoolLevel } from '@/module/domain/types'
import { useDomainLoading } from '@/module/domain/hook'
import { getDateString } from '@/module/domain/reducer'
import { useSchoolList } from '@/api/hook'
import debounce from 'lodash/debounce'
import clsx from 'clsx'

type DomainAddFormProps = {
  onAdd: (domainInfo: DomainInfo) => void
  onCancel: () => void
}

function DomainAddForm({ onAdd, onCancel }: DomainAddFormProps) {
  const [form, setForm] = useState<DomainInfo>(initialDomain)

  const [schoolName, setSchoolName] = useState<string>('')
  const [searchSchoolName, setSearchSchoolName] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const { data: schools = [] } = useSchoolList(searchSchoolName)

  const { loading } = useDomainLoading()

  const debouncedSearch = useCallback(
    debounce((value) => setSearchSchoolName(value), 500),
    [],
  )

  useEffect(() => {
    if (dropdownRef.current && selectedIndex >= 0) {
      const $parentEl = dropdownRef.current
      const childrenHeight = $parentEl.children[0]?.clientHeight // 1개 자식 요소 높이
      const parentOffset = childrenHeight * selectedIndex // 현재 포커스된 요소 "이전"까지의 총 높이
      const scrollTop = $parentEl.scrollTop // 현재 스크롤 위치
      const viewport = scrollTop + $parentEl.clientHeight // ul 높이 + 스크롤 높이

      if (viewport < parentOffset + childrenHeight) {
        $parentEl.scrollTop += childrenHeight
      }

      if (parentOffset < scrollTop) {
        $parentEl.scrollTop = parentOffset
      }
    }
  }, [selectedIndex])

  const determineSchoolType = (selectedSchool: {
    SCHUL_CRSE_SC_VALUE?: string
    SCHUL_NM: string
  }): TSchoolLevel => {
    const { SCHUL_CRSE_SC_VALUE, SCHUL_NM } = selectedSchool

    // 코드로 학교 유형 결정
    if (SCHUL_CRSE_SC_VALUE === '2') return 'elementary'
    if (SCHUL_CRSE_SC_VALUE === '3') return 'middle'
    if (SCHUL_CRSE_SC_VALUE === '4') return 'high'

    // 코드로 결정되지 않은 경우, 이름으로 학교 유형 결정
    if (SCHUL_NM.includes('초등학교')) return 'elementary'
    if (SCHUL_NM.includes('중학교')) return 'middle'
    if (SCHUL_NM.includes('고등학교')) return 'high'

    // 위 조건에 해당하지 않는 경우
    return 'other'
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onAdd(form)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target

    if (name === 'limitMaxUser') {
      setForm((prev) => ({ ...prev, maxLicense: checked ? (form.maxLicense ?? 0) : undefined }))
    } else if (name === 'maxLicense') {
      setForm((prev) => ({ ...prev, maxLicense: parseInt(value) }))
    } else if (name === 'locale') {
      if (!form.address) {
        setForm((prev) => ({ ...prev, locale: checked ? 'en' : 'ko' }))
        setForm((prev) => ({ ...prev, address: '' }))
      } else {
        if (
          confirm(
            `${checked ? '해외' : '국내'} 도메인으로 변경하시겠습니까? 입력한 주소는 초기화됩니다.`,
          )
        ) {
          setForm((prev) => ({ ...prev, locale: checked ? 'en' : 'ko' }))
          setForm((prev) => ({ ...prev, address: '' }))
        }
      }
    } else {
      setForm((prev) => ({ ...prev, [name]: value }))
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleAddMonth = () => {
    const value = new Date(form.expireTime)
    const month = value.getMonth()
    value.setMonth(month + 1)
    const date = getDateString(value)

    setForm((prev) => ({ ...prev, expireTime: date }))
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleSchoolSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSchoolName(value)
    debouncedSearch(value.replaceAll(' ', ''))
    setSelectedIndex(-1)
    setShowDropdown(true)
  }

  const handleBlur = () => {
    if (showDropdown) {
      setTimeout(() => setShowDropdown(false), 100)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (showDropdown) {
      if (e.nativeEvent.isComposing) {
        return
      }
      if (e.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, schools.length - 1))
      } else if (e.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0))
      } else if (e.key === 'Enter' && selectedIndex >= 0) {
        e.preventDefault()
        handleSchoolSelect(schools[selectedIndex])
      }
    }
  }

  const handleSchoolSelect = (selectedSchool: SchoolInfoDto) => {
    const schoolType: TSchoolLevel = determineSchoolType(selectedSchool)

    let schoolDomain = selectedSchool.HMPG_ADRES

    const match = schoolDomain?.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)(\/.*)?$/)
    if (match) {
      const domain = match[1]
      const path = match[2] || ''

      schoolDomain = domain + (domain.includes('school') ? path : '')
    }

    setSchoolName('')
    setForm((prev) => ({
      ...prev,
      domain: schoolDomain,
      name: selectedSchool.SCHUL_NM,
      type: schoolType,
      address: selectedSchool.SCHUL_RDNMA,
    }))
    setShowDropdown(false)
  }

  const handleCancel = () => {
    setForm({ ...initialDomain, type: 'other', address: '' })
    setSchoolName('')
    setSearchSchoolName('')
    onCancel()
  }

  return (
    <form onSubmit={handleSubmit} className='space-y-2'>
      <div className='relative flex items-center'>
        <div className='form-label flex'>
          <label htmlFor='school'>한국 학교 검색</label>
        </div>
        <div className='relative w-full items-center justify-start gap-2'>
          <input
            type='text'
            id='school'
            placeholder='학교 검색'
            name='school'
            value={schoolName}
            onBlur={handleBlur}
            onChange={handleSchoolSearch}
            onKeyDown={handleKeyDown}
            className='form-text-input'
            autoComplete='off'
          />
          {showDropdown && (
            <div
              className='form-textarea absolute left-0 top-full z-10 mt-1 max-h-72 w-full overflow-y-auto rounded border border-gray-300 bg-white'
              ref={dropdownRef}
            >
              {schools.length > 0 ? (
                schools.map((school, index) => (
                  <div
                    key={school?.SCHUL_CODE}
                    className={clsx('cursor-pointer p-1 hover:bg-gray-100', {
                      'bg-gray-200': selectedIndex === index,
                    })}
                    onMouseDown={() => handleSchoolSelect(school)}
                  >
                    <div className='font-medium'>
                      {school?.SCHUL_NM + (school?.ABSCH_YN === 'Y' ? ' (폐교)' : '')}
                    </div>
                    <div className='text-xs text-gray-400'>
                      {(school?.ADRES_BRKDN ?? school?.SCHUL_RDNMA ?? '') +
                        ' ' +
                        (school?.DTLAD_BRKDN ?? '')}
                    </div>
                  </div>
                ))
              ) : (
                <div className='p-2 text-sm text-gray-500'>학교를 찾을 수 없습니다.</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='flex items-center'>
        <div className='form-label flex'>
          <label htmlFor='domain'>도메인</label>
          <i className='fa-thin fa-asterisk fa-md text-error'></i>
        </div>

        <input
          required
          pattern='(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)'
          title='도메인 형식에 맞게 입력해주세요.'
          type='text'
          id='domain'
          placeholder='도메인'
          name='domain'
          value={form.domain?.trim()}
          onChange={handleChange}
          className='form-text-input'
        />
      </div>
      <div className='flex items-center'>
        <div className='form-label flex'>
          <label htmlFor='name'>단체명</label>
          <i className='fa-light fa-asterisk fa-md text-error'></i>
        </div>

        <input
          type='text'
          id='name'
          required
          pattern='.*\S.*'
          title='공백이 포함되어있지 않은지 확인하세요.'
          placeholder='단체명'
          name='name'
          value={form.name}
          onChange={handleChange}
          className='form-text-input'
        />
      </div>
      <div className='flex'>
        <label htmlFor='description' className='form-label mt-1'>
          설명
        </label>
        <textarea
          className='form-textarea'
          id='description'
          name='description'
          onChange={handleTextareaChange}
          value={form.description}
          placeholder='설명'
        ></textarea>
      </div>
      <div className='py-1' />
      <div className='flex items-center'>
        <label htmlFor='expireTime' className='form-label'>
          만료일
        </label>
        <div className='flex w-full gap-2'>
          <input
            type='date'
            id='expireTime'
            name='expireTime'
            value={form.expireTime}
            onChange={handleChange}
            className='form-text-input basis-3/5'
          />
          <button
            className='btn-sm btn basis-2/5 gap-1 bg-neutral lowercase text-white'
            type='button'
            onClick={handleAddMonth}
          >
            <div className='flex'>
              <i className='fa-solid fa-add fa-xs'></i>
              <i className='fa-solid fa-1 fa-xs'></i>
            </div>
            <span>month</span>
          </button>
        </div>
      </div>
      <div className='flex items-center'>
        <label htmlFor='maxLicense' className='form-label'>
          최대 유저 수
        </label>
        <div className='flex w-full items-center gap-2'>
          {form.maxLicense !== undefined ? (
            <input
              type='number'
              min={0}
              id='maxLicense'
              name='maxLicense'
              className='form-text-input basis-3/5'
              value={form.maxLicense}
              onChange={handleChange}
            />
          ) : (
            <input type='text' disabled value='무제한' className='form-text-input basis-3/5' />
          )}
          <div className='flex basis-2/5'>
            <input
              data-tip='최대 유저 수 제한'
              type='checkbox'
              id='limitMaxUser'
              name='limitMaxUser'
              className='form-toggle tooltip tooltip-primary'
              checked={form.maxLicense !== undefined}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className='py-1' />
      <div className='flex items-center'>
        <label htmlFor='locale' className='form-label'>
          해외 도메인 여부
        </label>
        <div className='flex w-full items-center justify-start gap-2'>
          <input
            type='checkbox'
            id='locale'
            name='locale'
            className='checkbox checkbox-primary'
            checked={form.locale === 'en'}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className='flex items-center justify-start'>
        <div className='form-label flex'>
          <label htmlFor='type'>학교 구분</label>
          <i className='fa-light fa-asterisk fa-md text-error'></i>
        </div>
        <div className='flex w-full items-center justify-start gap-2'>
          <select
            className='select-bordered select-primary select select-sm max-w-xs'
            onChange={handleSelectChange}
            id='type'
            name='type'
            value={form.type}
            required
          >
            <option value='elementary'>초등학교</option>
            <option value='middle'>중학교</option>
            <option value='high'>고등학교</option>
            <option value='univ&coll'>대학교</option>
            <option value='other' selected>
              기타
            </option>
          </select>
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <div className='form-label flex'>
          <label htmlFor='address'>주소</label>
          {form.locale === 'ko' && <i className='fa-light fa-asterisk fa-md text-error'></i>}
        </div>
        <div className='h-18 flex w-full flex-col'>
          {form.locale === 'en' ? (
            <input
              type='text'
              id='address'
              pattern='.*\S.*'
              title='공백이 포함되어있지 않은지 확인하세요.'
              placeholder='주소 직접 입력'
              name='address'
              value={form.address}
              onChange={handleChange}
              className='form-text-input'
            />
          ) : (
            <div className='flex flex-col gap-2'>
              <input
                type='text'
                id='address'
                required
                readOnly
                pattern='.*\S.*'
                title='공백이 포함되어있지 않은지 확인하세요.'
                placeholder='주소'
                name='address'
                value={form.address}
                onClick={() => {
                  new daum.Postcode({
                    oncomplete: function (data) {
                      setForm((prev) => ({ ...prev, address: data.address }))
                    },
                    theme: {
                      searchBgColor: '#181830',
                      queryTextColor: '#FFFFFF',
                    },
                  }).open({
                    popupKey: 'search-domain-address',
                    popupTitle: '도메인 단체 주소 검색',
                  })
                }}
                className='form-text-input'
              />
            </div>
          )}
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <div></div>
        <div className='mt-2 flex justify-end space-x-2'>
          <button
            className={`btn-primary btn-sm btn ${loading.save ? 'loading' : ''}`}
            type='submit'
          >
            저장
          </button>
          <button className='btn-sm btn bg-neutral text-white' type='button' onClick={handleCancel}>
            취소
          </button>
        </div>
      </div>
    </form>
  )
}

export default DomainAddForm
