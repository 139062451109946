export type DomainState = {
  domain: {
    itemsAll: DomainInfo[]
    items: DomainInfo[]
    selected: DomainInfo | null
    count: number
    userCount: number
    filterText: string
    filterLocale: TLocale | null
  }
  domainValidEmail: {
    itemsAll: string[]
    items: string[]
    selected: string | null
    count: number
    filterText: string
  }
  domainAdminEmail: {
    itemsAll: DomainUser[]
    items: DomainUser[]
    selected: DomainUser | null
    count: number
    filterText: string
  }
  loading: {
    save: boolean
    delete: boolean
  }
}

export type ValidType = 'email' | 'domain'

export type ValidEmails = {
  emails: string[]
}

export type TLocale = 'ko' | 'en'

export type TSchoolLevel = 'elementary' | 'middle' | 'high' | 'univ&coll' | 'other'

/**
 * maxLicense:  undefined인 경우 앱에서는 '무제한'으로 표시
 * userCountingTime, userCount가 undefined일 경우 앱에서는 '등록 유저 없음'으로 표시
 * domain: key 역할
 * validType: email / domain(혹은 없음) | Education 만 해당
 */
export type DomainInfoDto = {
  validType?: ValidType
  maxLicense?: number
  userCountingTime?: number
  userCount?: number
  updateTime?: number
  createTime: number
  description: string
  name: string
  expireTime: number
  domain: string
  locale: TLocale
  address?: string
  adminCount?: number
  type?: TSchoolLevel
}

export type CreateDomainInfoDto = {
  validType?: ValidType
  maxLicense?: number
  createTime: number
  description: string
  name: string
  expireTime: number
  domain: string
  locale?: TLocale
  address?: string
  adminCount?: number
  type?: TSchoolLevel
}

export type CreateDomainValidEmailDto = {
  domain: string
  body: ValidEmails
}

export type DeleteDomainValidEmailDto = CreateDomainValidEmailDto

export type PutDomainInfoDto = CreateDomainInfoDto & {
  userCount: number
  userCountingTime: number
}

export type DeleteDomainDto = {
  domain: string
}

export type PatchDomainUserDto = {
  domain: string
  data: {
    idxList: number[]
    level: number
  }
}

export type DomainUser = { email: string; idx: number }

export type DomainInfo = {
  validType?: ValidType
  maxLicense?: number
  userCountingTime?: string
  userCount?: number
  updateTime?: string
  createTime: string
  description: string
  name: string
  expireTime: string
  domain: string
  locale: TLocale
  address?: string
  adminCount?: number
  type?: TSchoolLevel
}

export type DomainUserParams = {
  level?: number
  teacher?: boolean
  grade?: number
  class?: string
  description?: string
}

// level 1: 일반, 2: 관리자
export type DomainUserDto = {
  idx: number
  domain: string
  email: string
  teacher: boolean
  level: number
  grade: number | null
  class: string | null
  description: string | null
}

export type SchoolInfoDto = {
  SCHUL_RDNMA: string
  SCHUL_FOND_TYP_CODE: string
  FOAS_MEMRD: string
  DGHT_SC_CODE: string
  FOND_SC_CODE: string
  USER_TELNO_SW: string
  LTTUD: number
  ZIP_CODE: string
  SCHUL_KND_SC_CODE: string
  LGTUD: number
  SCHUL_RDNZC: string
  DTLAD_BRKDN: string
  USER_TELNO: string
  ADRCD_NM: string
  COEDU_SC_CODE: string
  PERC_FAXNO: string
  JU_ORG_NM: string
  ATPT_OFCDC_ORG_NM: string
  JU_ORG_CODE: string
  USER_TELNO_GA: string
  HMPG_ADRES: string
  ADRES_BRKDN: string
  CLOSE_YN: string
  SCHUL_CODE: string
  FOND_YMD: string
  ATPT_OFCDC_ORG_CODE: string
  LCTN_SC_CODE: string
  ADRCD_CD: string
  ADRCD_ID: string
  SCHUL_RDNDA: string
  ABSCH_YN: string
  BNHH_YN: string
  SCHUL_NM: string
  SCHUL_CRSE_SC_VALUE?: string
  SCHUL_CRSE_SC_VALUE_NM?: string
  ABSCH_YMD?: string
}

export const DOMAIN_SET_DOMAIN_LIST = 'DOMAIN/SET_OPEN_DOMAIN_LIST' as const
export const DOMAIN_SET_SELECTED_DOMAIN = 'DOMAIN/SET_SELECTED_DOMAIN' as const
export const DOMAIN_SET_FILTER_TEXT = 'DOMAIN/SET_FILTER_TEXT' as const
export const DOMAIN_SET_FILTER_LOCALE = 'DOMAIN/SET_FILTER_LOCALE' as const
export const DOMAIN_FILTER_DOMAIN_LIST = 'DOMAIN/FILTER_DOMAIN_LIST' as const
export const DOMAIN_SET_DOMAIN_COUNT = 'DOMAIN/SET_DOMAIN_COUNT' as const
export const DOMAIN_SET_DOMAIN_USER_COUNT = 'DOMAIN/SEL_DOMAIN_USER_COUNT' as const
export const DOMAIN_SET_LOADING_SAVE = 'DOMAIN/SET_LOADING_SAVE' as const
export const DOMAIN_SET_LOADING_DELETE = 'DOMAIN/SET_LOADING_DELETE' as const
export const DOMAIN_SET_VALID_EMAIL_LIST = 'DOMAIN/SET_VALID_EMAIL_LIST' as const
export const DOMAIN_SET_SELECTED_VALID_EMAIL = 'DOMAIN/SET_SELECTED_VALID_EMAIL' as const
export const DOMAIN_SET_VALID_EMAIL_FILTER_TEXT = 'DOMAIN/SET_VALID_EMAIL_FILTER_TEXT' as const
export const DOMAIN_FILTER_VALID_EMAIL_LIST = 'DOMAIN/FILTER_VALID_EMAIL_LIST' as const
export const DOMAIN_SET_VALID_EMAIL_COUNT = 'DOMAIN/SET_VALID_EMAIL_COUNT' as const
export const DOMAIN_SET_ADMIN_EMAIL_LIST = 'DOMAIN/SET_ADMIN_EMAIL_LIST' as const
export const DOMAIN_SET_SELECTED_ADMIN_EMAIL = 'DOMAIN/SET_SELECTED_ADMIN_EMAIL' as const
export const DOMAIN_SET_ADMIN_EMAIL_FILTER_TEXT = 'DOMAIN/SET_ADMIN_EMAIL_FILTER_TEXT' as const
export const DOMAIN_FILTER_ADMIN_EMAIL_LIST = 'DOMAIN/FILTER_ADMIN_EMAIL_LIST' as const

export type Action =
  | { type: typeof DOMAIN_SET_DOMAIN_LIST; domainInfoList: DomainInfoDto[] }
  | { type: typeof DOMAIN_SET_SELECTED_DOMAIN; selectedDomain: DomainInfo | null }
  | {
      type: typeof DOMAIN_SET_FILTER_TEXT
      filterText: string
    }
  | {
      type: typeof DOMAIN_FILTER_DOMAIN_LIST
    }
  | {
      type: typeof DOMAIN_SET_FILTER_LOCALE
      filterLocale: TLocale | null
    }
  | {
      type: typeof DOMAIN_SET_DOMAIN_COUNT
      domainCount: number
    }
  | {
      type: typeof DOMAIN_SET_DOMAIN_USER_COUNT
      domainUserCount: number
    }
  | {
      type: typeof DOMAIN_SET_LOADING_SAVE
      loadingSave: boolean
    }
  | {
      type: typeof DOMAIN_SET_LOADING_DELETE
      loadingDelete: boolean
    }
  | {
      type: typeof DOMAIN_SET_VALID_EMAIL_LIST
      validEmailList: string[]
    }
  | {
      type: typeof DOMAIN_SET_SELECTED_VALID_EMAIL
      selectedValidEmail: string
    }
  | {
      type: typeof DOMAIN_SET_VALID_EMAIL_FILTER_TEXT
      filterText: string
    }
  | {
      type: typeof DOMAIN_SET_VALID_EMAIL_COUNT
      validEmailCount: number
    }
  | {
      type: typeof DOMAIN_FILTER_VALID_EMAIL_LIST
    }
  | {
      type: typeof DOMAIN_SET_ADMIN_EMAIL_LIST
      payload: DomainUser[]
    }
  | {
      type: typeof DOMAIN_SET_SELECTED_ADMIN_EMAIL
      payload: DomainUser
    }
  | {
      type: typeof DOMAIN_SET_ADMIN_EMAIL_FILTER_TEXT
      payload: string
    }
  | {
      type: typeof DOMAIN_FILTER_ADMIN_EMAIL_LIST
    }
