import React from 'react'

function DomainListSkeleton() {
  const bgAnimate = 'bg-gray-100 animate-pulse'

  return (
    <div>
      <div className='flex w-full items-center justify-between'>
        <div className='mx-6 flex space-x-2'>
          <div className={`h-[18px] w-[55px] rounded-xl text-xs ${bgAnimate}`}></div>
          <div className={`h-[18px] w-[65px] rounded-xl text-xs ${bgAnimate}`}></div>
        </div>
        <div className='px-3 pb-1'>
          <button className='btn-ghost btn-sm btn items-center space-x-1'>
            <i className='fa-solid fa-circle-plus animate-pulse text-gray-100'></i>
            <span className='animate-pulse text-sm text-gray-100'>도메인 추가</span>
          </button>
        </div>
      </div>
      <ul className='menu grow px-4 text-sm text-base-content'>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
          <div key={i} className='flex w-full items-center justify-between rounded-lg'>
            <div className='space-y-1 p-4'>
              <div className={`h-[15px] w-12 ${bgAnimate}`}></div>
              <div className={`h-[15px] w-40 ${bgAnimate}`}></div>
            </div>
            <div className='flex flex-col rounded-lg p-4'>
              <div className='flex justify-end'>
                <div className={`h-[15px] w-12 ${bgAnimate}`}></div>
              </div>
              <div className={`h-[5px] w-20 ${bgAnimate}`}></div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  )
}

export default DomainListSkeleton
