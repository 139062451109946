import React, { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { menuList } from '@/module/home/constant'
import { useOpened, useSelected } from '@/module/home/hook'
import { useAuth } from '@/module/auth/hook'

function Header() {
  const { user } = useAuth()
  const {
    openProductDropwdown,
    openProfileDropdown,
    setOpenProductDropdown,
    setOpenProfileDropwdown,
  } = useOpened()
  const { productType, domain: selectedDomain, validEmailType, adminList } = useSelected()
  const [displayDropdown, setDisplayDropdown] = useState({ product: '', profile: '' })

  const activeTitle = useMemo(() => {
    const currentProduct = menuList.find((menu) => menu.id === productType)?.name
    const isDomainSelected = selectedDomain.domain !== ''

    return (
      <div className='px-0 lg:px-4'>
        <Link to={`/${productType}`}>{currentProduct}</Link>
        {isDomainSelected && (
          <>
            <span className='px-1'>/</span>
            <Link to={`/${productType}/${selectedDomain.domain}`}>{selectedDomain.name}</Link>
          </>
        )}
        {isDomainSelected && validEmailType && (
          <>
            <span className='px-1'>/</span>
            <a>허용 이메일 설정</a>
          </>
        )}
        {isDomainSelected && adminList && (
          <>
            <span className='px-1'>/</span>
            <a>관리자 이메일 설정</a>
          </>
        )}
      </div>
    )
  }, [productType, selectedDomain, validEmailType, adminList])

  const handleBarClick = () => {
    setOpenProductDropdown(!openProductDropwdown)
    setDisplayDropdown(
      !openProductDropwdown
        ? { ...displayDropdown, product: '' }
        : { ...displayDropdown, product: 'none' },
    )
  }

  const handleProfileClick = () => {
    setOpenProfileDropwdown(!openProfileDropdown)
    setDisplayDropdown(
      !openProfileDropdown
        ? { ...displayDropdown, profile: '' }
        : { ...displayDropdown, profile: 'none' },
    )
  }

  return (
    <div className='sticky top-0 z-50 flex items-center bg-base-100/20 px-2 backdrop-blur-[2px] backdrop-filter'>
      <div className='flex-none lg:hidden'>
        <div className='dropdown' onClick={handleBarClick}>
          <label tabIndex={0} className='btn-ghost btn-sm btn-circle btn ml-1'>
            <i className='fa fa-thin fa-bars text-gray-400'></i>
          </label>
          <ul
            tabIndex={0}
            style={{ display: displayDropdown.product }}
            className='dropdown-content menu mt-3 w-52 rounded-box bg-base-100 p-2 shadow'
          >
            <li className='menu-title'>
              <span>상품 목록</span>
            </li>
            {menuList.map((menu) => (
              <li key={menu.id}>
                <Link to={`/${menu.id}`} className={`${menu.id === productType ? 'active' : ''}`}>
                  {menu.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='flex-1 truncate text-xs text-gray-500'>{activeTitle}</div>
      <div className='flex-none'>
        <div className='flex items-center'>
          <div className='dropdown-left dropdown' onClick={handleProfileClick}>
            <label tabIndex={0} className='btn-ghost btn-circle avatar btn'>
              <div className='w-8 rounded-full'>
                <img src={user.picture} alt={`${user.name}'s profile`} />
              </div>
            </label>
            <ul
              tabIndex={0}
              style={{ display: displayDropdown.profile }}
              className='dropdown-content menu mt-3 w-52 rounded-box bg-base-100 p-2 shadow'
            >
              <li>
                <a href={process.env.REACT_APP_LOGOUT_URL}>Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
