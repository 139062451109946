import React from 'react'

type DividerProps = {
  container?: string
  children: React.ReactNode
  icon?: React.ReactNode
}

function Heading({ children, container, icon }: DividerProps) {
  return (
    <div className={`${container ?? ''} flex items-center gap-2 pb-2`}>
      {icon}
      <div className='text font-bold'>{children}</div>
    </div>
  )
}

export default Heading
