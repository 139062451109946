import React from 'react'

function EmailListSkeleton() {
  const bgAnimate = 'bg-gray-100 animate-pulse'

  return (
    <div className='grow px-4'>
      <div className='flex items-center justify-between pb-2 pl-2'>
        <div>
          <div className='btn-ghost btn-sm btn font-normal'>
            <div className={`h-[18px] w-[55px] rounded-xl text-xs ${bgAnimate}`}></div>
            <span className='text-xs'> 선택</span>
          </div>
        </div>
        <div>
          <div className='btn-ghost btn-sm btn'>
            <i className='fa-solid fa-minus mr-1 animate-pulse text-gray-100'></i>
            <span className='animate-pulse text-sm text-gray-100'>삭제</span>
          </div>
          <div className='btn-ghost btn-sm btn ml-1 items-center'>
            <i className='fa-solid fa-plus mr-1 animate-pulse text-gray-100'></i>
            <span className='animate-pulse text-sm text-gray-100'>추가</span>
          </div>
        </div>
      </div>

      <div className='mt-0'>
        <ul className='menu text-sm text-base-content'>
          {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
            <li key={i}>
              <label>
                <input type='checkbox' className='checkbox-ghost checkbox checkbox-xs'></input>
                <div className={`h-[15px] w-12 ${bgAnimate}`}></div>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default EmailListSkeleton
