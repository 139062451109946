import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '@/module/auth/hook'
import { menuList } from '@/module/home/constant'
import { useDebug, useSelected } from '@/module/home/hook'
import { ProductType } from '@/types'

function Home() {
  const { isAuth, user, handleSetUser } = useAuth()
  const { setSelectedProductType } = useSelected()
  const { debug } = useDebug()

  return (
    <div className='flex w-full items-center justify-center'>
      <div className='hero min-h-screen bg-base-200'>
        <div className='hero-content text-center'>
          <div className='max-w-md'>
            <h1 className='font-bold uppercase'>
              <span className='text-4xl'>Flexcil</span>
              <p>
                <span className='text-4xl'>Domain Manager</span>
              </p>
            </h1>
            <p className='py-6'>
              안녕하세요. {isAuth ? `${user.name}님, ` : ''}플렉슬 제품의 도메인 관리 페이지입니다.
            </p>
            {isAuth ? (
              <div>
                <p>상품을 선택하세요.</p>
                <ul className='menu rounded-box p-2'>
                  {menuList.map((menu) => (
                    <li
                      key={menu.id}
                      onClick={() => {
                        setSelectedProductType(menu.id as ProductType)
                      }}
                    >
                      <Link to={`/${menu.id}`}>{menu.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div
                className='btn-primary btn gap-2'
                onClick={() => {
                  if (debug) {
                    handleSetUser({
                      locale: 'ko',
                      name: '김유정',
                      email: 'yjeong711@flexcil.com',
                      picture:
                        'https://lh3.googleusercontent.com/a/ACg8ocJLCJgcSTC2XkvNdKbQHcO_Ch_K4bqYNpYlQTU_zS-tzMUkjw=s96-c',
                    })

                    return
                  }

                  const res = window.open(
                    process.env.REACT_APP_LOGIN_URL,
                    'Sconn User Manager Login',
                    'popup=true,width=450,height=480',
                  )
                  if (!res) return
                  const resTick = setInterval(() => {
                    if (res.location.pathname === '/') {
                      res.close()
                    }
                    if (res.closed) {
                      clearInterval(resTick)
                      const params = new URLSearchParams(window.location.search)
                      const redirectTo = params.get('redirectTo')
                      if (redirectTo?.endsWith('/list')) {
                        params.set('redirectTo', redirectTo.slice(0, -5))
                      }
                      params.set('loggedIn', 'true')
                      const decodeParams = decodeURIComponent(params.toString())
                      window.location.replace(`/?${decodeParams}`)
                    }
                  }, 500)
                }}
              >
                <i className='fa-brands fa-google'></i>
                <span>구글로 로그인하기</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
