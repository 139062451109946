import React from 'react'
import DomainAddForm from './DomainAddForm'
import { DomainInfo } from '@/module/domain/types'

export const ADD_DOMAIN_MODAL = 'add-domain-modal'

type DomainAddModalProps = {
  onAdd: (domainInfo: DomainInfo) => void
  onCancel: () => void
}

function DomainAddModal({ onAdd, onCancel }: DomainAddModalProps) {
  return (
    <dialog id={ADD_DOMAIN_MODAL} className='modal modal-bottom sm:modal-middle'>
      <div className='modal-box relative'>
        <h3 className='text-lg font-bold'>도메인 추가</h3>
        <div className='pt-4'>
          <DomainAddForm onAdd={onAdd} onCancel={onCancel} />
        </div>
      </div>
    </dialog>
  )
}

export default DomainAddModal
