import React, { Fragment, lazy, Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '@/components/DomainManagement'

const DomainConfigurationManagement = lazy(
  async () => await import('../components/DomainConfigurationManagement'),
)

function DomainConfiguration() {
  /**
   * TODO: loading spinner to suspense fallback
   */
  return (
    <Fragment>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err) => Sentry.captureException(err)}
      >
        <Suspense
          fallback={
            <div className='flex justify-center'>
              <div className='mt-4 animate-pulse text-2xl text-gray-300'>로딩 중입니다.</div>
            </div>
          }
        >
          <DomainConfigurationManagement />
        </Suspense>
      </ErrorBoundary>
    </Fragment>
  )
}

export default DomainConfiguration
