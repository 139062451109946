import React from 'react'
import { menuList } from '@/module/home/constant'
import { Link } from 'react-router-dom'
import { useSelected } from '@/module/home/hook'
import { useAuth } from '@/module/auth/hook'

function SideMenu() {
  const { user } = useAuth()
  const { productType } = useSelected()

  return (
    <header className='navbar hidden h-screen bg-base-100 lg:flex lg:w-[300px] lg:flex-col'>
      <div className='justify-left w-full flex-none'>
        <div className='select-none p-3 font-bold'>
          <span className='text-xl text-primary'>Flexcil</span>
          <p>
            <span className='text-2xl'>Domain Manager</span>
          </p>
        </div>
      </div>
      <div className='mt-2 w-full flex-1 items-start'>
        <ul className='menu w-full rounded-box p-2'>
          <li className='menu-title'>
            <span>상품 목록</span>
          </li>
          {menuList.map((menu) => (
            <li key={menu.id}>
              <Link to={`/${menu.id}`} className={`${menu.id === productType ? 'active' : ''}`}>
                {menu.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className='flex w-full items-center'>
        <div className='dropdown-top dropdown'>
          <label tabIndex={0} className='btn-ghost btn-circle avatar btn'>
            <div className='w-8 rounded-full'>
              <img src={user.picture} alt={`${user.name}'s profile`} />
            </div>
          </label>
          <ul
            tabIndex={0}
            className='dropdown-content menu mt-3 w-52 rounded-box bg-base-100 p-2 shadow'
          >
            <li>
              <a href={process.env.REACT_APP_LOGOUT_URL}>Logout</a>
            </li>
          </ul>
        </div>
        <div className='text-sm'>{user.name}</div>
      </div>
    </header>
  )
}

export default SideMenu
