import React from 'react'

function Footer() {
  return (
    <footer className='footer footer-center bg-base-100 p-4 text-base-content'>
      <div>
        <p>© 2024. Flexcil Inc.</p>
      </div>
    </footer>
  )
}

export default Footer
