import axios from 'axios'
import {
  CreateDomainInfoDto,
  CreateDomainValidEmailDto,
  DeleteDomainDto,
  DeleteDomainValidEmailDto,
  DomainUserDto,
  DomainUserParams,
  PatchDomainUserDto,
  SchoolInfoDto,
} from '@/module/domain/types'
import { PutDomainInfoDto } from './../module/domain/types'
import { Paths } from './paths'

export async function fetchBizDomainList() {
  const result = await axios.get(Paths.GetBizDomain, {
    withCredentials: true,
  })

  return result.data
}

export async function createBizDomain(dto: CreateDomainInfoDto) {
  return await axios.post(Paths.UpdateBizDomain, dto, {
    withCredentials: true,
  })
}

export async function modifyBizDomain(dto: PutDomainInfoDto) {
  return await axios.put(Paths.UpdateBizDomain, dto, {
    withCredentials: true,
  })
}

export async function deleteBizDomain(dto: DeleteDomainDto) {
  return await axios.delete(Paths.UpdateBizDomain, {
    data: dto,
    withCredentials: true,
  })
}

export async function fetchEduDomainList() {
  const result = await axios.get(Paths.GetEduDomain, {
    withCredentials: true,
  })

  return result.data

  // const result = await fetch('/edu-list.json').then(async (response) => await response.json())
  // return result
}

export async function createEduDomain(dto: CreateDomainInfoDto) {
  return await axios.post(Paths.UpdateEduDomain, dto, {
    withCredentials: true,
  })
}

export async function modifyEduDomain(dto: PutDomainInfoDto) {
  return await axios.put(Paths.UpdateEduDomain, dto, {
    withCredentials: true,
  })
}

export async function deleteEduDomain(dto: DeleteDomainDto) {
  return await axios.delete(Paths.UpdateEduDomain, {
    data: dto,
    withCredentials: true,
  })
}

export async function fetchEduValidEmailList(domain: string) {
  const result = await axios.get(`${Paths.GetEduEmail}/${domain}`, {
    withCredentials: true,
  })

  return result.data

  // const response = await fetch('/edu-emails-list.json')
  // const jsonData = await response.json()
  // console.log(jsonData)

  // return jsonData
}

export async function createEduValidEmails(dto: CreateDomainValidEmailDto) {
  return await axios.post(`${Paths.UpdateEduEmail}/${dto.domain}`, dto.body, {
    withCredentials: true,
  })
}

export async function deleteEduValidEmails(dto: DeleteDomainValidEmailDto) {
  return await axios.delete(`${Paths.UpdateEduEmail}/${dto.domain}`, {
    data: dto.body,
    withCredentials: true,
  })
}

export async function fetchEduDomainUsers(
  domain: string,
  params: DomainUserParams,
): Promise<DomainUserDto[]> {
  const result = await axios.get(
    `/api/edu/domain/${domain}/users${params.level ? `?level=${params.level}` : ''}`,
    {
      withCredentials: true,
    },
  )

  return result.data
}

export async function patchEduDomainUserLevel(data: PatchDomainUserDto) {
  return await axios.patch(`/api/edu/domain/${data.domain}/users/level`, data.data, {
    withCredentials: true,
  })
}

export async function fetchEduSchoolList(keyword: string): Promise<SchoolInfoDto[]> {
  const result = await axios.get(Paths.GetEduSchool, {
    params: {
      keyword,
    },
    withCredentials: true,
  })

  return result.data
}
