import {
  DomainInfoDto,
  DomainUserDto,
  DomainUserParams,
  PatchDomainUserDto,
  SchoolInfoDto,
  ValidEmails,
} from '@/module/domain/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import {
  createBizDomain,
  createEduDomain,
  createEduValidEmails,
  deleteBizDomain,
  deleteEduDomain,
  deleteEduValidEmails,
  fetchBizDomainList,
  fetchEduDomainList,
  fetchEduDomainUsers,
  fetchEduSchoolList,
  fetchEduValidEmailList,
  modifyBizDomain,
  modifyEduDomain,
  patchEduDomainUserLevel,
} from './domain'
import useRegExpMatch from '@/components/common/hooks/useRegExpMatch'

export function useDomainAPI() {
  const { productId } = useParams()
  const queryClient = useQueryClient()

  const query = useQuery<DomainInfoDto[], AxiosError>(
    ['domain', productId],
    async () => {
      if (productId === 'edu') {
        return await fetchEduDomainList()
      } else {
        return await fetchBizDomainList()
      }
    },
    { staleTime: 1000 * 60 * 5, suspense: true, retry: 0 },
  )

  const postMutation = useMutation({
    mutationFn: productId === 'edu' ? createEduDomain : createBizDomain,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', productId] })
    },
  })

  const putMutation = useMutation({
    mutationFn: productId === 'edu' ? modifyEduDomain : modifyBizDomain,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', productId] })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: productId === 'edu' ? deleteEduDomain : deleteBizDomain,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', productId] })
    },
  })

  return { query, postMutation, putMutation, deleteMutation }
}

export function useEmailListAPI() {
  const matches = useRegExpMatch()
  const domain = matches?.params.domain

  const queryClient = useQueryClient()
  const query = useQuery<ValidEmails, AxiosError>(
    ['emails', domain],
    async () => {
      if (domain) {
        return await fetchEduValidEmailList(domain)
      }
    },
    {
      staleTime: 1000 * 60 * 5,
      suspense: true,
      retry: 0,
    },
  )

  const postMutation = useMutation({
    mutationFn: createEduValidEmails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emails', domain] })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: deleteEduValidEmails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emails', domain] })
    },
  })

  return { query, postMutation, deleteMutation }
}

export function useDomainAdminEmailList(params: DomainUserParams) {
  const matches = useRegExpMatch()
  const domain = matches?.params.domain

  const query = useQuery<DomainUserDto[], AxiosError>(
    ['users', domain],
    async () => {
      if (domain) {
        return await fetchEduDomainUsers(domain, params)
      }

      return []
    },
    {
      staleTime: 1000 * 60 * 5,
      suspense: true,
      retry: 0,
    },
  )
  return { data: query.data, query }
}

export function usePatchDomainUser() {
  const matches = useRegExpMatch()
  const domain = matches?.params.domain

  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async (data: PatchDomainUserDto) => {
      if (domain) {
        return await patchEduDomainUserLevel(data)
      }

      return []
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users', domain] })
    },
  })

  return mutation
}

export function useSchoolList(keyword: string) {
  const query = useQuery<SchoolInfoDto[], AxiosError>(
    ['schools', keyword],
    async () => {
      if (!keyword) return []
      return await fetchEduSchoolList(keyword)
    },
    {
      enabled: !!keyword,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )

  return { data: query.data, query }
}
