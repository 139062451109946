import React, { useEffect, useState } from 'react'
import { useDomainList } from '@/module/domain/hook'
import { DomainInfo } from '@/module/domain/types'
import EmptyList from './common/EmptyList'

type DomainListProps = {
  list: DomainInfo[]
  onClickListItem: (domain: DomainInfo) => void
  onClickEditDomainPage: (domain: DomainInfo) => void
}

function DomainList({ list, onClickListItem, onClickEditDomainPage }: DomainListProps) {
  return (
    <ul className='px-4 text-sm text-base-content'>
      {list.length === 0 && <EmptyList />}
      {list.map((domainInfo) => (
        <li key={domainInfo.domain}>
          <a
            className='collapse p-3 hover:bg-base-200 active:bg-primary'
            onClick={(e) => {
              onClickListItem(domainInfo)
            }}
          >
            <DomainListItem domain={domainInfo} onClickEditDomainPage={onClickEditDomainPage} />
          </a>
        </li>
      ))}
    </ul>
  )
}

type DomainListItemProps = {
  domain: DomainInfo
  onClickEditDomainPage: (domain: DomainInfo) => void
}

function DomainListItem({ domain, onClickEditDomainPage }: DomainListItemProps) {
  const [open, setOpen] = useState<boolean>(false)
  const { selectedDomain, setSelectedDomain } = useDomainList()

  useEffect(() => {
    setOpen(domain.domain === selectedDomain?.domain)
  }, [selectedDomain])

  return (
    <>
      <input
        type='checkbox'
        className='!min-h-fit'
        checked={open}
        onChange={(e) => {
          setSelectedDomain(e.target.checked ? domain : null)
        }}
      />
      <div className='collapse-title !min-h-fit p-0'>
        <div className='flex items-center justify-between'>
          <div className={`${open ? 'font-semibold' : ''}`}>
            <div>{domain.name}</div>
            <div className='flex items-center gap-2 text-gray-400'>
              <span>{domain.domain}</span>
              {open && (
                <span className='badge badge-ghost badge-outline badge-sm'>
                  {domain.locale ?? 'ko'}
                </span>
              )}
            </div>
          </div>
          {open ? (
            <div
              className='btn-ghost btn-active btn-xs btn z-50'
              onClick={() => onClickEditDomainPage(domain)}
            >
              수정
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='flex justify-end text-xs text-gray-400'>
                {domain.userCount ?? '0'}/{domain.maxLicense ?? '무제한'}
              </div>
              <progress
                className='progress-accent progress h-[5px] w-20'
                value={domain.userCount ?? 0}
                max={domain.maxLicense}
              ></progress>
            </div>
          )}
        </div>
      </div>
      <div className='collapse-content mt-2 -mb-4 p-0 text-xs text-gray-400'>
        <div className='grid-row-2 grid grid-flow-col justify-items-stretch gap-2'>
          <p className='w-full whitespace-pre-line'>{domain.description}</p>
          <div className='w-full'>{domain.expireTime} 만료</div>
          <div className='row-span-2 w-24 justify-self-end'>
            <div className='flex justify-between'>
              <span>등록: </span>
              <span>{domain.userCount ?? '0'}</span>
            </div>
            <div className='flex justify-between'>
              <span>라이선스: </span>
              <span>{domain.maxLicense ?? '무제한'}</span>
            </div>
            <div>{domain.validType === 'email' && '(허용 이메일 유저)'}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DomainList
